<script setup lang="ts">
import { getVideoByDeviceCodeApi, getVideoDeviceLiveApi } from '@/api/video'
import { getDeviceVideoUrl } from '@/utils/video'

const props = defineProps(['visible', 'deviceCode', 'deviceName'])
const emits = defineEmits(['onClose'])
const message = useMessage()

const videoDeviceLive = ref<VideoDeviceLive>({})
const videoUrl = ref<string>('')
const seekable = ref<boolean>(false)
const audioEnabled = ref(false)

watch(() => props.visible, async () => {
  if (props.visible) {
    if (props.deviceCode) {
      const { channels, audioEnabled: audio } = await getVideoByDeviceCodeApi(props.deviceCode)
      audioEnabled.value = audio ?? false
      if (channels && channels.length > 0) {
        await onChangeChannel(channels[0])
      }
    }
    else {
      message.warning('无视频')
    }
  }
})

async function onChangeChannel(val: Channel) {
  videoDeviceLive.value = await getVideoDeviceLiveApi(val.name, props.deviceCode)
  seekable.value = videoDeviceLive.value.seekable as boolean
  videoUrl.value = getDeviceVideoUrl(videoDeviceLive.value.url ?? '', videoDeviceLive.value.proxied ?? false) as string
}

function onClose() {
  videoUrl.value = ''
  emits('onClose')
}
</script>

<template>
  <a-modal
    :open="visible"
    :title="deviceName ?? ''"
    centered
    :width="599"
    :footer="null"
    @cancel="onClose"
  >
    <div class="video">
      <video-player :has-audio="audioEnabled" :video-url="videoUrl" :visible="visible" :seekable="seekable" />
    </div>
  </a-modal>
</template>

<style lang="scss" scoped>
.video {
  margin-top: 10px;
	height: 400px;
	width: 550px;
}
</style>
